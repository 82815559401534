/**
 * Created on 1/6/2016.
 */

angular.module('membership')
  .run([
    '$rootScope',
    'membershipConfig',
    'modalDialogService',
    'BizUsers',
    'UserRegisterDomain',
    'authorizeService',
    'ImpersonationService',

    function ($rootScope,
              membershipConfig,
              modalDialogService,
              BizUsers,
              UserRegisterDomain,
              authorizeService,
              ImpersonationService) {

      $rootScope.$on(membershipConfig.events.renderingUserActions, function (scope, data) {
        if (!data.handler.remove) {
          data.handler.remove = function (userId) {
            modalDialogService.showConfirmation("Are you sure want to delete this user ?", "User deletion confirmation", "dialog-warning").then(function (result) {
              if (result) {
                BizUsers.remove(userId).then(function () {
                  modalDialogService.showAlert("User deleted successfully", "Delete User Alert", "dialog-success");
                }, function () {
                  modalDialogService.showAlert("Error while deleting user", "Delete User Alert", "dialog-warning");
                }).finally(function () {
                  data.handler.triggerSearch();
                });
              }
            });
          };
        }

        if (!data.handler.approveUser) {
          data.handler.approveUser = function (userId) {
            UserRegisterDomain.approveUser(userId).then(function () {
              data.handler.triggerSearch();
            });
          };
        }

        if (!data.handler.deactivateUser) {
          data.handler.deactivateUser = function (userId) {
            modalDialogService.showConfirmation("Are you sure want to deactivate this user ?", "User deactivation confirmation", "dialog-warning").then(function (result) {
              if (result) {
                BizUsers.deactivateUser(userId).finally(data.handler.triggerSearch);
              }
            });
          }
        }

        if (!data.handler.reactivateUser) {
          data.handler.reactivateUser = function (userId) {
            modalDialogService.showConfirmation("Are you sure want to reactivate this user ?", "User reactivation confirmation", "dialog-warning").then(function (result) {
              if (result) {
                BizUsers.reactivateUser(userId).finally(data.handler.triggerSearch);
              }
            });
          };
        }

        if (!data.handler.impersonate && data.isSysadmin && authorizeService.isFeatureAllowed("/sysadmin")) {
          data.handler.impersonate = function (userId) {
            modalDialogService.showConfirmation("Are you sure want to impersonate this user ?", "User impersonation confirmation", "dialog-warning").then(function (result) {
              if (result) {
                ImpersonationService.impersonate(userId).then(function () {
                  window.location.href = '/';
                });
              }
            });
          };
        }

        // var editBtn = "<a class='btn btn-success btn-xs' ui-sref='membership_userAccount.personalInfo({userId: " + data.user.Id + "})' title='Edit' ><i class='fa fa-edit'></i></a>";
        var editBtn = "<a class='btn btn-success' ui-sref='membership_customerUser.userProfile.general({key: " + data.user.Id + "})' title='Edit' ><i class='fa fa-edit'></i></a>";

        var deleteBtn = "<a class='btn btn-danger btn-xs' ng-if='!vm.filter.showDeletedOnly' ng-click='vm.remove(" + data.user.Id + ")' title='Delete'><i class='fa fa-times'></i></a>";

        data.actionButtons.push({
          template: editBtn,
          order: 0
        });

        data.actionButtons.push({
          template: deleteBtn,
          order: 1
        });

        if (!data.user.IsApproved && authorizeService.isFeatureAllowed(membershipConfig.permissions.approveUserRegistration)) {
          data.actionButtons.push({
            template: "<a class='btn btn-info btn-xs' ng-click='vm.approveUser(" + data.user.Id + ")' title='Approve user' ><i class='fa fa-check'></i></a>",
            order: 10
          });
        }

        if (!data.user.IsLockedOut && authorizeService.isFeatureAllowed(membershipConfig.permissions.activateDeactivateUsers)) {
          data.actionButtons.push({
            template: "<a class='btn btn-danger btn-xs' ng-click='vm.deactivateUser(" + data.user.Id + ")' title='Deactivate user' ><i class='fa fa-ban'></i></a>",
            order: 10
          });
        }

        if (data.user.IsLockedOut && authorizeService.isFeatureAllowed(membershipConfig.permissions.activateDeactivateUsers)) {
          data.actionButtons.push({
            template: "<a class='btn btn-warning btn-xs' ng-click='vm.reactivateUser(" + data.user.Id + ")' title='Reactivate user' ><i class='fa fa-check'></i></a>",
            order: 10
          });
        }

        if (data.isSysadmin && authorizeService.isFeatureAllowed("/sysadmin")) {
          data.actionButtons.push({
            template: "<a class='btn btn-success btn-xs' ng-click='vm.impersonate(" + data.user.Id + ")' title='Impersonate this user' ><i class='fa fa-exchange'></i></a>",
            order: 10
          });

        }

        if (authorizeService.isFeatureAllowed(membershipConfig.permissions.undoDeletionOfUser)) {
          data.actionButtons.push({
            template: "<a class='btn btn-success btn-xs' ng-if='vm.filter.showDeletedOnly' ng-click='vm.undoDeletion(" + data.user.Id + ")' title='Undo deletion this user' ><i class='fa fa-user-plus'></i></a>",
            order: 10
          });
          data.handler.undoDeletion = function (userId) {
            modalDialogService.showConfirmation("Are you sure want to undo deletion this user ?", "User undo deletion confirmation", "dialog-warning").then(function (result) {
              if (result) {
                BizUsers.undoDeletion(userId).then(function () {
                  window.location.reload();
                });
              }
            });
          };
        }
      });
    }
  ]);
