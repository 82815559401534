class UploadController {
	constructor(scope, ajaxService) {
		this._scope = scope;
		this._scope.file = null;
		this._ajaxService = ajaxService;

		this._scope.getClasses = () => {
			const classes = (this._scope.overrideStyles ? '' : 'upload-zone drop-box ') + this._scope.ctrlClass;
			console.log(classes)
			return classes;
		}
		this._scope.onFileSelected = async () => {
			if (this._scope.holdFileInsteadOfUploadImmediately) {
				console.log(this._scope.file)

				await this._scope.fileSelected(this._scope.file);
			} else {
				await this.uploadFile(this._scope.file);
			}
		}
	}

	async uploadFile($file) {
		if (this._scope.uploading)
			return;

		this._scope.uploading = true;

		if (this._scope.onUploadStarting) {
			this._scope.onUploadStarting($file);
		}

		try {
			let data = await this._ajaxService.upload(this._scope.uploadUrl, $file);

			if (this._scope.onUploadSucceeded) {
				this._scope.onUploadSucceeded(data);
			}
		} catch (error) {
			if (this._scope.onUploadFailed) {
				this._scope.onUploadFailed(error);
			}
		} finally {
			this._scope.uploading = false;
		}
	}
}

angular.module('common')
	.directive('uploadController', [
		'AjaxService',
		function (AjaxService) {
			return {
				restrict: "EA",
				replace: true,
				transclude: {
					'content': '?content',
				},
				templateUrl: (element, attributes) => {
					if (attributes.type === 'button')
						return "components/common/directives/uploadController/uploadController.button.tpl.html";

					return attributes.templateUrl || "components/common/directives/uploadController/uploadController.tpl.html";
				},
				scope: {
					uploadUrl: '=',
					fileSelected: '=',
					holdFileInsteadOfUploadImmediately: '=',
					onUploadSucceeded: '=',
					onUploadStarting: '=',
					invalidFiles: '=',
					acceptedFiles: '@',
					onUploadFailed: '=',
					multiple: '=',
					dropOnly: '=',
					overrideStyles: '=',
					ctrlClass: '@'
				},
				controller: classInjector(['$scope', 'AjaxService'], UploadController),
				link: function (scope, element, attribute) {

				}
			}
		}
	]);
