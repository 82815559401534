'use strict';

angular.module('notifications')
			 .factory('sendNotificationVm', [
				 'NotificationMessageRepository', '$q', 'AjaxService', 'membershipConfig', 'AwaitService', 'modalDialogService',
				 'SiteSettingService', 'notificationsConfig', 'NotificationScheduleRepository', '$state', 'userUiService', 'permissionsService',
				 'ProfileDomain', '$filter',
				 'commonConfig',
				 function (NotificationMessageRepository, $q, AjaxService, config, AwaitService, modalDialogService,
									 SiteSettingService,
									 notificationsConfig,
									 NotificationScheduleRepository,
									 $state,
									 userUiService,
									 permission,
									 profileDomain,
									 $filter,
									 commonConfig) {


					 function sendNotificationVm(reminderId, userId, scope) {
						 let self = this;

						 self.userId = userId && Number.parseInt(userId);
						 self.notificationsConfig = notificationsConfig;
						 self.mode = 'USER';
						 self.type = 'sms';
						 self.usersSelected = [];
						 self.emailBody = '';
						 self.subject = '';
						 self.message = '';
						 self.showScheduler = false;
						 self.submitted = false;
						 self.changeTriggerVersion = 1;
						 self.maxSizeAllowed = '20MB';

						 self.scheduled = {
							 Date: new Date(),
							 Time: new Date()
						 };


						 self.resetForm = function () {
							 self.usersSelected = [];
							 self.emailBody = '';
							 self.subject = '';
							 self.message = '';
						 };

						 scope.$watch('invalidFiles', (error) => {
							 if (error && error.$error === 'maxSize') {
								 modalDialogService.showError(`File size exceeded: ${(error.size / Math.pow(1024, 2)).toFixed(2)} MB, max allowed: ${self.maxSizeAllowed}`, `File Size Exceeded`, `dialog-warning`);
							 }
						 }, true);

						 if (!!reminderId) {
							 NotificationScheduleRepository.getById(reminderId).then(function (reminder) {
								 self.type = reminder.NotificationType === 'Email' ? 'email' : 'sms';
								 self.subject = reminder.Subject;
								 self.message = reminder.Message;

								 if (reminder.SavedUsers) {
									 self.usersSelected = reminder.SavedUsers.Users;
									 self.mode = reminder.SavedUsers.Mode;
								 }


								 self.scheduled.Date = new Date(reminder.ScheduledDate);
								 self.scheduled.Time = new Date(reminder.ScheduledDate);
								 self.showScheduler = true;
							 });
						 }

						 if (!!self.userId) {
							 var url = config.apiUrls.base + `/api/user/${self.userId}/personalinfo`;
							 AjaxService.get(url, null, { IsBackground: true }).then((contactToAdd) => {
							 		console.log(contactToAdd);

								 const shouldHideEmail = self.notificationsConfig.hideEmailFromContactSelection;
								 const r = {
									 PhoneNumber: contactToAdd.CellPhoneNumber,
									 Email: contactToAdd.Email,
									 FirstName: contactToAdd.FirstName,
									 LastName: contactToAdd.LastName,
									 AliasName: contactToAdd.AliasName,
									 Id: contactToAdd.Id
								 };

								 const aliasString = r.AliasName ? ` | ${r.AliasName} ` : '';
								 let contactString = '';

								 const contactInfo = [!shouldHideEmail && r.Email, r.PhoneNumber].filter(_ => !!_)
									 .join(', ');

								 if (!!contactInfo) {
									 contactString = ` | ${contactInfo}`;
								 }

								 r.Name = `${r.FirstName} ${r.LastName}${aliasString}${contactString}`;

								 self.usersSelected.push(r);
							 })
						 }

						 self.notificationSetting = SiteSettingService.getInstance(notificationsConfig.settingsKey);


						 profileDomain.getMyPersonalInfo().then(user => {
							 console.log(user)

							 self.notificationSetting.getSetting().then(async () => {
								 self.emailAddresses = self.notificationSetting.data.EmailSettings;

								 const isHasPermission = await permission.hasPermission('/sysadmin') || _.includes(user.RoleCodes, 'CA');
								 console.log('p: ', isHasPermission)
								 if (isHasPermission) {
									 self.phoneNumbers = self.notificationSetting.data.PhoneNumberSettings;

									 self.defaultPhoneNumber = _.find(self.notificationSetting.data.PhoneNumberSettings, (number) => number.IsDefault);

									 self.selectedPhoneNumber = self.selectedPhoneNumber || self.defaultPhoneNumber || (self.phoneNumbers || [])[0];
								 } else {
									 console.log(self.notificationSetting.data.PhoneNumberSettings, user.Id);


									 self.phoneNumbers = self.notificationSetting.data.PhoneNumberSettings.filter(n => n.PrimaryUserId === user.Id ||
										 n.UserIds && n.UserIds.some(u => u === user.Id) ||
										 n.IsDefault);
									 self.defaultPhoneNumber = _.find(self.notificationSetting.data.PhoneNumberSettings, (number) => number.IsDefault);

									 let numberToSelect = self.phoneNumbers.find(n => n.PrimaryUserId === user.Id);

									 if (!numberToSelect) {
										 numberToSelect = self.defaultPhoneNumber;
									 }

									 if (numberToSelect) {
										 self.selectedPhoneNumber = numberToSelect;
									 }
								 }

								 self.phoneNumbers.forEach(_ => {
									 _.formattedPhoneNumber = $filter('maskOutput')(_.PhoneNumber.replace(/^\+1/, ''), commonConfig.phoneInputMask);
								 });


								 self.selectedAddress = self.selectedAddress || (self.emailAddresses || [])[ 0 ];
							 });
						 });

						 self.setType = function (t) {
							 self.type = t;

							 // self.checkEmailPhoneValid();
						 };

						 self.isType = function (t) {
							 return self.type === t;
						 };

						 self.isFormValid = function () {
							 if (self.type === 'email') {
								 return self.usersSelected.length && self.subject && self.message;
							 }
							 return self.usersSelected.length && self.subject;
						 };

						 self.onNewContactNeedAdded = async function (newContact, inputResult, formatContact) {
							 console.log('adding new contact', newContact);

							 const guid = new Date().getTime();

							 newContact.Email = guid + '@shkacpas.com';
							 newContact.Password = guid;

							 console.log(newContact.Email, newContact.Password);

							 const createdUser = await userUiService.showCreateUserDialog('', newContact, {
								 showEmailInAdditionalInfo: true,
								 disablePasswordField: true,
								 isPhoneNumberRequired: true
							 });

							 const outputResult = inputResult;

							 console.log(outputResult);

							 // add created user to outputResult;
							 if (createdUser) {
							 	const contactToAdd = formatContact(createdUser);

							 	outputResult.push(contactToAdd);
							 }

							 console.log('created user: ', createdUser, outputResult[outputResult.length - 1]);
							 return outputResult;
						 };

						 self.onItemClicked = function (item) {
							 self.validateForm();
							 if (self.mode === 'CUSTOMER') {
								 self.showCustomerMembers(item);
							 }
							 else if (self.mode === 'GROUP') {
								 self.showGroupMembers(item);
							 } else {
								 if (item.__error) {
									 userUiService.editUser(item.Id, 'This user has no email or phone set up, thus you cannot send notification to this person. Please update user information below').then(function (response) {
										console.log(response);
									 	item.PhoneNumber = response.CellPhoneNumber;
										 item.Email = response.Email;
										 item.__error = false;
									 });
								 } else {
									 let str = `${item.FirstName} ${item.LastName} (${item.Email}, ${item.CellPhoneNumber})`;
									 modalDialogService.showAlert(str, 'User Info', 'alert-info', true);
								 }
							 }
						 };

						 self.fileSelected = function () {
							 self.attachment = self.attachmentFile && self.attachmentFile.name;
						 };

						 self.onFileSelected= (file) => {
						 	console.log(file)
							 self.attachmentFile = file[0];
						 };

						 self.manageGroup = function () {
							 modalDialogService.show_Window(
								 'components/notifications/adapters/manageGroupDlg.html',
								 'UserGroupListCtrl',
								 {},
								 'lg x-lg'
							 ).then(function () {
								 self.changeTriggerVersion++;
							 });
						 };


						 self.selectedUsersValid = false;
						 self.checkingUserRunning = false;

						 self.onSelectedUserChange = function () {
							 self.validateForm();
						 };

						 // self.checkEmailPhoneValid = function () {
						 // 	self.checkingUserRunning = true;
						 // 	if (_.some(self.usersSelected, function (r) {
						 // 			let b = r[self.isType('email') ? 'Email' : 'PhoneNumber'] === '';
						 // 			r.__error = !!b;
						 //
						 // 			return b;
						 // 		})) {
						 // 		self.selectedUsersValid = false;
						 // 		modalDialogService.showAlert(
						 // 			'There is one or more contacts have no ' + (self.isType('email') ? 'Email' :
						 // 'Phone number') + '. Please check the selected user and update information before
						 // sending.', 'Invalid users found'); } else { self.selectedUsersValid = true; }
						 // self.checkingUserRunning = false; };

						 self.getUsersContacts = function () {

							 if (self.mode !== 'USER') {
								 return getUserContactsFromGroupOrCustomer();
							 }

							 let defer = $q.defer();

							 let contacts = self.usersSelected.map(_ => self.isType('email') ? _.Email : _.PhoneNumber);
							 // let contacts = self.usersSelected;
							 defer.resolve(contacts);

							 return defer.promise;
						 };

						 function getUserContactsFromGroupOrCustomer() {

							 let url = config.apiUrls.base;
							 if (self.mode === 'GROUP') {
								 url += '/api/userGroup/getUsers/';
							 }
							 if (self.mode === 'CUSTOMER') {
								 url += '/customers/getUsers/';
							 }

							 let defer = $q.defer();

							 let selectedContactIds = self.usersSelected;

							 let promises = [];
							 let contacts = [];

							 selectedContactIds.forEach(function (contact) {
								 promises.push(AjaxService.get(url + contact.Id).then((data) => {
									 let cData = _.map(data, self.isType('email') ? 'Email' : 'CellPhoneNumber');
									 contacts = contacts.concat(cData);
								 }));
							 });

							 $q.all(promises).finally(() => {
								 defer.resolve(contacts);
							 });

							 return defer.promise;
						 }

						 function getUserContactsFromCustomer() {
							 let defer = $q.defer();

							 let selectedContactIds = self.usersSelected;

							 let promises = [];
							 let contacts = [];

							 selectedContactIds.forEach((contact) => {
								 if (contact.Id) {
									 promises.push(AjaxService.get(config.apiUrls.base + '/customers/getUsers/' + contact.Id)
																						.then((data) => {
																							contacts = contacts.concat(_.map(data, self.isType('email') ? 'Email' : 'CellPhoneNumber'));
																						}));
								 }
							 });

							 $q.all(promises).finally(function () {
								 defer.resolve(contacts);
							 });

							 return defer.promise;
						 }

						 self.showGroupMembers = function (group) {
							 AjaxService.get(config.apiUrls.base + '/api/userGroup/getUsers/' + group.Id)
													.then((data) => {
														let members = _.map(data, (x, index) => {
															return `<h4>${index + 1}. ${x.FirstName} ${x.LastName} (${x.Email}, ${x.CellPhoneNumber})</h4>`;
														});
														modalDialogService.showAlert(members.join(''), `Group Info - ${group.Name}`, 'alert-info', true);
													});
						 };

						 self.showCustomerMembers = function (customer) {
							 AjaxService.get(config.apiUrls.base + '/customers/getUsers/' + customer.Id).then((data) => {
								 let members = _.map(data, function (x, index) {
									 return `<h4>${index + 1}. ${x.FirstName} ${x.LastName} (${x.Email}, ${x.CellPhoneNumber})</h4>`;
								 });
								 modalDialogService.showAlert(members.join(''), `Customer Info - ${customer.Name}`, 'alert-info', true);
							 });
						 };

						 self.deleteAttachment = function () {
							 modalDialogService.showConfirmation('Do you want to remove the attachment ?', 'Delete attachment confirmation', 'dialog-danger').then((response) => {
								 if (response) {
									 self.attachmentFile = null;
								 }
							 });
						 };

						 self.validateForm = function () {
							 if (!self.submitted) {
								 return;
							 }

							 self.isValidationError = false;
							 if (!self.usersSelected || self.usersSelected.length === 0 || !self.subject || (!self.message && self.isType('email'))) {
								 self.isValidationError = true;
								 self.validationMessage = 'Select at least one user, group or customer and fill message body first.';
								 return;
							 }

							 if (self.showScheduler) {
								 let date = self.scheduled.Date;
								 let time = self.scheduled.Time;
								 let scheduledDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(),
									 time.getHours(), time.getMinutes(), time.getSeconds());

								 if (scheduledDate < new Date()) {
									 self.isValidationError = true;
									 self.validationMessage = 'Please select schedule date in future.';
									 return;
								 }
							 }
						 };

						 self.send = function () {
							 self.isSent = false;
							 self.isError = false;
							 self.submitted = true;
							 self.errorMessage = '';
							 self.validateForm();

							 if (self.isValidationError) {
								 return;
							 }

							 self.getUsersContacts().then(function (contacts) {
								 if (!contacts || contacts.length === 0) {
									 modalDialogService.showAlert('All selected recipients has empty ' + (self.isType('email') ? 'email' : 'phone number'), 'Error', 'dialog-danger');
									 return;
								 }
								 if (_.some(contacts, function (c) {
										 return !c;
									 })) {
									 modalDialogService.showAlert('Some recipients has empty ' + (self.isType('email') ? 'email' : 'phone number'), 'Error', 'dialog-danger');
									 return;
								 }
								 //debugger;

								 let model = {
									 id: reminderId || null,
									 notificationType: self.type,
									 subject: self.subject,
									 message: self.message,
									 targets: contacts
								 };

								 if (self.isType('sms')) {
									 model.from = self.selectedPhoneNumber.PhoneNumber;
								 } else {
									 model.from = self.selectedAddress.EmailAddress;
									 model.fromDisplayName = self.selectedAddress.DisplayName;
								 }

								 if (self.showScheduler) {
									 let date = self.scheduled.Date;
									 let time = self.scheduled.Time;
									 model.scheduledDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(),
										 time.getHours(), time.getMinutes(), time.getSeconds());

									 model.savedUsers = {
										 users: self.usersSelected,
										 mode: self.mode
									 };
								 }

								 self.isLoading = true;
								 let respPromise;
								 if (self.attachmentFile) {
									 respPromise = NotificationMessageRepository.sendMessageWithAttachment(model, self.attachmentFile);
								 } else {
									 model.attachments = [];
									 respPromise = NotificationMessageRepository.createMessage(model);
								 }

								 respPromise.then(function (resp) {
									 self.submitted = false;
									 resp = resp.data || resp;
									 self.isLoading = false;
									 self.isSent = resp.IsSuccess;
									 if (!self.isSent) {
										 modalDialogService.showAlert('Error while sending notification:<br />' + resp.Errors.join('<br />'), 'Error while sending message', 'dialog-warning');
									 } else {
										 self.attachment = null;
										 self.attachmentFile = null;
										 modalDialogService.showPopupNotification("Message sent successfully", 'alert-success');

										 // if (self.mode == 'USER') {
										 //  let newUsers = _.map(_.filter(self.usersSelected, function (x) {
										 // 	 return !x.Id;
										 //  }), function (x) {
										 // 	 if (self.type == 'sms') {
										 // 		 return {
										 // 			 CellPhoneNumber: x.PhoneNumber,
										 // 			 NoEmailNeeded: true,
										 // 			 IsVirtual: true
										 // 		 };
										 // 	 } else {
										 // 		 return {
										 // 			 Email: x.Email
										 // 		 };
										 // 	 }
										 //  });
										 //  _.each(newUsers, function (user) {
										 // 	 AjaxService.post(config.apiUrls.base + '/user/CreateUser', user);
										 //  });
										 // }
										 // redirect to reminder if schedule date is configured
										 if (self.showScheduler) {
											 $state.go('notifications.reminder');
										 } else {
											 self.resetForm();
										 }
									 }
								 }, function (error) {
									 self.isLoading = false;
									 modalDialogService.showError(error, 'Error while sending message', 'dialog-danger', true);
								 });
							 });
						 };
					 }

					 return sendNotificationVm;
				 } ]);
