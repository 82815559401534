/**
 * Created on 5/31/2016.
 */

'use strict';

angular.module('notifications')
			 .factory('InboxViewModel', [
				 'NotificationMessageRepository',
				 'notificationsConfig',
				 '$state',
				 'SiteSettingService',
				 '$filter',
				 'commonConfig',
				 'ProfileDomain',
				 'permissionsService',
				 function (NotificationMessageRepository,
									 notificationsConfig,
									 $state,
									 SiteSettingService,
									 $filter,
									 commonConfig,
									 profileDomain,
									 permission) {
					 function InboxViewModel() {
						 let self = this;
						 self.defaultPhoneNumber = {};
						 self.phoneNumbers = null;
						 self.selectedPhoneNumber = null;
						 self.pageSize = 10;
						 self.pageIndex = 1;

						 self.notificationSetting = SiteSettingService.getInstance(notificationsConfig.settingsKey);

						 self.optionsList = [];
						 self.originalData = [];

						 self.getDisplayValue = function (r) {
							 if (!r) {
								 return null;
							 }

							 if (/^\+1(?:\d?){10}$/.test(r.Name)) { // US number
								 return $filter('maskOutput')(r.Name.replace(/^\+1/, ''), commonConfig.phoneInputMask);
							 } else if (/^\+84(?:\d?){10}$/.test(r.Name)) {
								 // record.FormattedClientNumber = record.ClientNumber;
								 return $filter('maskOutput')(r.Name.replace(/^\+/, ''), '(+99) 999-999-999');
							 } else {
								 return r.Name;
							 }
						 };

						 self.reload = function (filter) {
							 let items = self.originalData.map(r => {
								 return {
									 Id: r.ClientNumber,
									 Name: r.ClientNumber
								 }
							 }).filter(number => number.Name.includes(filter));

							 self.optionsList = [ {
								 Id: null,
								 Name: 'Show All'
							 } ].concat(items);

							 self.data = _.filter(self.originalData, function (data) {
								 return _.includes(data.ClientNumber, filter);
							 });
						 };

						 self.onSearchSelectedChange = function () {
							 self.data = self.originalData.filter((data) => !self.selectedValue.selected.Id ||
								 _.eq(data.ClientNumber, self.selectedValue.selected.Id));

							 self.refreshInboxDetail();
						 };

						 self.pageChanged = function (newPageNumber) {
							 self.pageIndex = newPageNumber;
							 self.reloadData();
						 };

						 self.reloadData = function (force) {
							 if (force) {
								 self.pageIndex = 1;
							 }
							 self.loadData(self.selectedPhoneNumber.PhoneNumber);
						 };

						 self.loadData = function (phoneNumber) {
							 NotificationMessageRepository.getAll(phoneNumber, self.pageIndex - 1, self.pageSize).then(function (response) {
								 self.totalItems = response.TotalRecords;
								 self.data = _.orderBy(response.Data.map(function (record) {
									 record.ClientNumber = record.To == self.selectedPhoneNumber.PhoneNumber ? record.From : record.To;

									 if (/^\+1(?:\d?){10}$/.test(record.ClientNumber)) { // US number
										 record.FormattedClientNumber = $filter('maskOutput')(record.ClientNumber.replace(/^\+1/, ''), commonConfig.phoneInputMask);
									 } else if (/^\+84(?:\d?){10}$/.test(record.ClientNumber)) {
										 // record.FormattedClientNumber = record.ClientNumber;
										 record.FormattedClientNumber = $filter('maskOutput')(record.ClientNumber.replace(/^\+/, ''), '(+99) 999-999-999');
									 } else {
										 record.FormattedClientNumber = record.ClientNumber;
									 }

									 return record;
								 }), [ 'CreatedDate' ], [ 'desc' ]);

								 self.originalData = _.cloneDeep(self.data);

								 self.optionsList = [ {
									 Id: null,
									 Name: 'Show All'
								 } ].concat(_.map(self.originalData, function (r) {
									 return {
										 Id: r.ClientNumber,
										 Name: r.ClientNumber
									 }
								 }));

								 self.refreshInboxDetail();
								 console.log(self.data);

							 }, function (error) {

							 });
						 };

						 self.renderCollaboratorName = function (record) {
							 if (record.Collaborator && (record.Collaborator.FirstName || record.Collaborator.LastName)) {
								 let displayName = (record.Collaborator.FirstName || '') + ' ' + (record.Collaborator.LastName || '');

								 console.log(record);
								 if (record.Collaborator.AliasName) {
									 displayName += ' (' + record.Collaborator.AliasName + ') ';
								 }

								 displayName += ' [' + record.FormattedClientNumber + ']';

								 return displayName;
							 }

							 return record.FormattedClientNumber;
						 };

						 self.refreshInboxDetail = function () {
							 if (self.data && self.data.length > 0) {
								 $state.go('notifications.inbox.detail', {
									 threadId: self.data[ 0 ].ThreadId
								 });
							 }
							 else {
								 $state.go('notifications.inbox.detail', {
									 threadId: ''
								 });
							 }
						 };

						 // self.notificationSetting.getSetting().then(function () {
							//  self.defaultPhoneNumber = _.find(self.notificationSetting.data.PhoneNumberSettings, function (number) {
							// 	 return number.IsDefault;
							//  }) || {};
						 //
							//  self.phoneNumbers = self.notificationSetting.data.PhoneNumberSettings;
							//  self.selectedPhoneNumber = self.defaultPhoneNumber;
							//  self.loadData(self.defaultPhoneNumber.PhoneNumber);
						 // });

						 profileDomain.getMyPersonalInfo().then(user => {
							 console.log(user)

							 self.notificationSetting.getSetting().then(async () => {
								 const isHasPermission = await permission.hasPermission('/sysadmin') || _.includes(user.RoleCodes, 'CA');
								 console.log('p: ', isHasPermission)
								 if (isHasPermission) {
									 self.phoneNumbers = self.notificationSetting.data.PhoneNumberSettings;

									 self.defaultPhoneNumber = _.find(self.notificationSetting.data.PhoneNumberSettings, (number) => number.IsDefault);

									 self.selectedPhoneNumber = self.selectedPhoneNumber || self.defaultPhoneNumber || (self.phoneNumbers || [])[0];
								 } else {
									 self.phoneNumbers = self.notificationSetting.data.PhoneNumberSettings.filter(n => n.PrimaryUserId === user.Id ||
										 n.UserIds && n.UserIds.some(u => u === user.Id) ||
										 n.IsDefault);
									 self.defaultPhoneNumber = _.find(self.notificationSetting.data.PhoneNumberSettings, (number) => number.IsDefault);

									 let numberToSelect = self.phoneNumbers.find(n => n.PrimaryUserId === user.Id);

									 if (!numberToSelect) {
										 numberToSelect = self.defaultPhoneNumber;
									 }

									 if (numberToSelect) {
										 self.selectedPhoneNumber = numberToSelect;
									 }
								 }

								 self.phoneNumbers.forEach(_ => {
									 _.formattedPhoneNumber = $filter('maskOutput')(_.PhoneNumber.replace(/^\+1/, ''), commonConfig.phoneInputMask);
								 });

								 console.log('load for', self.selectedPhoneNumber.PhoneNumber)
								 self.loadData(self.selectedPhoneNumber.PhoneNumber);
							 });
						 });

					 }

					 return InboxViewModel;
				 }
			 ]);
